.containerResults {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 95%;
}

.fixedPaymentResultsColumns {
    min-width: 150px;
}

.tableContainerSearch {
    width: 100%;
    font-size: small;
    height: 600px;
    overflow-y: auto;
    overflow-x: scroll;
}

.fixed-header thead th {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #d9d9d9;
}

.labelNotaria {
    margin-left: 10px;
}

.backBtn {
    background: none;
    border: none;
    padding: 0px !important;
    margin: 0px !important;
    display: flex;
}

.backBtn>svg {
    width: 20px;
    height: 20px;
}

.openModalBtn {
    background: none;
    border: none;
    padding: 0px !important;
    color: var(--bs-link-color);
    text-decoration: underline;
    --bs-btn-hover-bg: none !important;
}

.openModalBtn:hover {
    cursor: pointer;
    background-color: none !important;
    color: var(--bs-link-hover-color);
}

.modal-body {
    white-space: pre-wrap;
}

.searchDiv {
    margin-top: 3%;
    width: 70%;
}

.searchDiv>div {
    display: flex;
}

.searchDiv>div>svg {
    position: absolute;
    z-index: 1;
    margin: 11px;
}

.searchDiv>div>input {
    border: 0px;
    width: 92%;
    margin-left: 5%;
}

.searchDiv>div>input:focus-visible {
    outline: none;
}

.tdRed {
    color: red;
}

.css-b62m3t-container {
    width: 100%;
}

.openModalBtn {
    background: none;
    border: none;
    padding: 0px !important;
    color: var(--bs-link-color);
    text-decoration: underline;
    --bs-btn-hover-bg: none !important;
}

.openModalBtn:hover {
    cursor: pointer;
    background-color: none !important;
    color: var(--bs-link-hover-color);
}

.modalBodyProcessedData {
    display: flex;
    justify-content: space-evenly;
}

.dataContainer>div {
    margin-top: 10px;
}

.dataContainer>div>label {
    font-size: 11pt;
}

.modalSearch {
    max-width: 900px;
}

.fileContainer>img {
    display: block;
    width: 250px;
}

.h6TitleModal {
    text-align: center;
    margin-bottom: 3%;
}

.hoverTd {
    position: absolute;
    margin-top: 40%;
    background-color: beige;
    color: black;
    display: flex;
    flex-direction: column;
    z-index: 3;
}

.hoverTdArrow {
    position: absolute;
    margin-top: 20%;
    z-index: 5;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid beige;
}

.imageTooltip {
    width: 400px;
}

.spinnerIcon {
    margin-top: 30px;
    color: #6c757d;
}

.yellowElement {
    background-color: rgba(254, 255, 168, 255);
}

.highlightElement {
    padding: 0.5em 0;
    border-bottom: 1px solid #eee;
    background-color: #fff !important;
    transition: all 0.4s cubic-bezier(.8, 0, .2, 1);
}

.clearBtn {
    background: none;
    border: none;
    color: #6c757d;
    padding: 0px 0px 3px 0px;
    font-size: 11pt;
}

.clearBtn:hover,
.clearBtn:active {
    background: none;
}

.selectCurrency {
    width: 75px;
    text-align: center;
}

.modalContainerAssociate {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.associatePayments {
    align-items: center;
    margin-bottom: 5%;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.associatePayments>div {
    margin: 0px 2%;
    width: 50%;
    position: relative;
}

.inputAssociatePayments {
    margin: 0px 15px;
}

.tableAssociate {
    max-width: 60%;
}

.tableAssociate>tbody>tr>td>input {
    border: 1px solid hsl(0, 0%, 80%);
    width: 100%;
}

input[type="checkbox"] {
    cursor: pointer;
}

.inputSearchByContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 50%;
    margin-left: 20%;
}

.inputSearchByContainer>input {
    width: 10px;
}

.inputSearchByContainer>label {
    width: 100%;
}

.tableContainerSearchBy {
    justify-content: center;
    margin-top: 1%;
}

.tableContainerSearchBy>table {
    width: 40%;
}

.tableContainerSearchBy>table>tbody>tr>td>input {
    margin-right: 10px;
}

.tableContainerSearchBy>table>tbody>tr>td>input,
.tableContainerSearchBy>table>tbody>tr>td>label {
    cursor: pointer;
}

.exportBtnContainer {
    justify-content: end !important;
}

.emptySearch {
    margin-top: 5%;
}

.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
.ReactTags__tagInput {
    width: 200px;
    border-radius: 2px;
    display: inline-block;
    margin-bottom: 5px;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    border: 1px solid #eee;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #6c757d;
    color: white;
    font-size: 10px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    border-radius: 5px;
}

.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
    position: absolute;
    z-index: 5 !important;
}

.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
    padding-inline-start: 0px;
}

.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}

.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}

.ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: white;
}

input.tagInputClassKey {
    height: 31px;
    margin: 0;
    font-size: 10px;
    width: 100%;
    border: 1px solid #eee;
}

.exportButtonFooter {
    justify-content: center;
}

.containerModalChangeType {
    display: flex;
    justify-content: center;
}

.containerModalChangeType>div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 40%;
}

.containerModalChangeType>div>label {
    cursor: pointer;
}

.react-datepicker-popper {
    z-index: 50 !important;
}

::-webkit-scrollbar {
    appearance: none;
    width: 5px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.tagAgreement>div>div {
    width: 100%;
}

.exportOrder p {
    display: block;
    margin-bottom: 12px;
}

.exportOrder input {
    margin-right: 8px;
}

.addof {
    text-align: right;
}

.addof input {
    margin-right: 8px;
}

.expTitle {
    margin-bottom: 3rem !important;
}

nav.MuiPagination-root.MuiPagination-outlined.css-1oj2twp-MuiPagination-root {
    margin-bottom: 2%;
    height: 250px;
}

.h6TitleSearch {
    margin: 10px 0px 10px 0px;
    text-align: center;
}

.tableResultsContainer {
    width: 100% !important;
}

.numbSelected {
    width: 100%;
    text-align: left;
    min-height: 24px;
}

.checkboxContainer {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    max-width: 40%;
    margin: 0% 15% 20px;
}

.associatePaymentsSearch {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-top: 1%;
}

.associatePaymentsSearch>div {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top: 1%;
}

.associatePaymentsSearch>div>button {
    background: none;
    border: none;
    padding: 0px !important;
    color: var(--bs-link-color);
    text-decoration: underline;
    --bs-btn-hover-bg: none !important;
    margin-bottom: 1%;
}

.associatePaymentsSearch>div>button:hover {
    cursor: pointer;
    background-color: none !important;
    color: var(--bs-link-hover-color);
}

.notesImport {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    max-width: 80%;
}

.notesImport>input {
    width: 100%;
    max-width: 100%;
}

.fileUploaderDivSearch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.fileUploaderDivSearch>label {
    width: 80%;
    max-width: 80%;
    margin-bottom: 2%;
    height: 250px;
}

.h6TitleSearch {
    margin: 10px 0px 10px 0px;
    text-align: center;
}

.tableResultsContainer {
    width: 100% !important;
}

.numbSelected {
    width: 100%;
    text-align: left;
    min-height: 24px;
}