a.navbar-brand {
    align-items: center;
    display: flex;
}

.logoNavBar {
    height: 40px;
    width: 40px;
}

.h4Title {
    margin: 10px 0px 10px 0px;
}

.fileUploaderDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fileUploaderDiv>label {
    height: 250px;
    width: 400px;
    margin-bottom: 20px;
}

.sc-dkrFOg.dmajdp {
    justify-content: space-evenly !important;
}

span.file-types {
    width: 120px !important;
    max-width: 120px !important;
}

.divButtons {
    display: flex;
    justify-content: space-between;
    width: 20%;
}

.selectedFilesDiv {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.liFiles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.liFiles>button {
    margin-left: 20px;
}

.progressBarContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
}

.progress {
    width: 70%;
}

.importLinkContainer {
    margin-left: 15px;
    border-left: 1px solid rgb(120, 120, 120);
}

.mainLink {
    display: flex;
    align-items: center;
    margin-left: 0px !important;
}

.mainLink,
.agreementsLink,
.importLink {
    color: white;
    text-decoration: none;
    margin-left: 15px;
    font-size: 15pt;
}

.mainLink:hover,
.searchLink:hover,
.agreementsLink:hover,
.importLink:hover {
    color: rgb(120, 120, 120);
}

.navBarContainer {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
    height: 50px;
}

.checkboxContainer {
    display: flex;
    justify-content: space-evenly;
    width: 70%;
    max-width: 19%;
    margin: 0% 15% 20px;
}

.checkboxContainer>div {
    cursor: pointer;
}

.checkboxContainer>div>input {
    margin-right: 5px;
    cursor: pointer;
}

.checkboxContainer>div>label {
    cursor: pointer;
}

.logoutContainer {
    margin-left: 88%;
    position: absolute;
    display: flex;
}

.logoutButton {
    background: none;
    border: none;
    font-size: 22pt;
}

.logoutButton:hover {
    background: none;
}

.associatePaymentsMainPage {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-top: 1%;
}

.associatePaymentsMainPage>div {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-top: 1%;
}

.associatePaymentsMainPage>div>button {
    background: none;
    border: none;
    padding: 0px !important;
    color: var(--bs-link-color);
    text-decoration: underline;
    --bs-btn-hover-bg: none !important;
    margin-bottom: 1%;
}

.associatePaymentsMainPage>div>button:hover {
    cursor: pointer;
    background-color: none !important;
    color: var(--bs-link-hover-color);
}

.associatePaymentsTable>thead {
    background: none;
}

.associatePaymentsTable>tbody>tr>td>input {
    width: 100%;
}

.associatePaymentsMainPage>div {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-top: 1%;
}

.associatePaymentsMainPage>div>button {
    background: none;
    border: none;
    padding: 0px !important;
    color: var(--bs-link-color);
    text-decoration: underline;
    --bs-btn-hover-bg: none !important;
    margin-bottom: 1%;
}

.associatePaymentsMainPage>div>button:hover {
    cursor: pointer;
    background-color: none !important;
    color: var(--bs-link-hover-color);
}

.associatePaymentsTable>thead {
    background: none;
}

.associatePaymentsTable>tbody>tr>td>input {
    width: 100%;
}