.ReactPanZoomContainer {
    background-color: #dddddd;
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.ReactPanZoomContainer>div>div>div>img {
    height: 100vh !important;
}