#notification-bell {
  position: relative;
  width: 42px;
  top: 16px;
  right: 0px;
  cursor: pointer;
  z-index: 1000;
}

#notification-bell ul {
  position: absolute;
  top: 32px;
  background: white;
  width: 400px;
  max-height: 592px;
  overflow: scroll;
  right: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
  border-radius: 6px;
  border: 1px solid #ccc;
}

#notification-bell ul li {
  margin: 0;
  padding: 0 12px;
}

#notification-bell ul li a {
  color: blue;
  display: block;
  border-bottom: 1px solid #ccc;
  padding: 6px 0;
  font-size: 14px;
}

#notification-bell ul li:last-child a {
  border: none;
}

#notification-bell ul li a strong {
  font-weight: normal;
  display: block;
}

#notification-bell ul li a small {
  color: #666;
  text-transform: uppercase;
}

#notification-bell p {
  padding: 0;
  margin: 0;
}

.notificationUnread {
  background-color: #e9f4fe;
}

.markAllAsRead {
  text-align: right;
}

.markAllAsRead>a {
  font-size: 9pt !important;
}

.headerContainer {
  padding-bottom: 7%;
}

.headerContainer>nav {
  position: fixed;
  width: 100%;
  z-index: 11;
}

.settingsList {
  position: relative;
  width: 42px;
  right: 8px;
  cursor: pointer;
  z-index: 1000;
}

.settingsList ul {
  position: absolute;
  top: 44px;
  background: white;
  width: 200px;
  max-height: 592px;
  right: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.settingsList ul li a {
  color: black;
  text-decoration: none;
  align-items: center;
  display: flex;
  justify-content: center;
}

.settingsList ul li a:hover {
  text-decoration: underline;
}