.containerLogin {
    display: flex;
    flex-direction: column;
    margin-top: 10%;
}
.containerLogin > div > table {
    justify-content: center;
    display: flex;
    align-items: center;
}
.blindBtn {
    background: none;
    border: none;
    color: #6c757d;
    padding: 0px 0px 3px 0px;
    font-size: 11pt;
}
.blindBtn:hover {
    background: none;
    color: #6c757d;
}
.errorText {
    color: red;
    position: absolute;
    margin-top: -5px;
    padding: 0px 30px !important;
}
.btnContainerSession {
    margin-top: 2%;
}