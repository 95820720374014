.createUserPageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.createUserPageContainer table {
    width: 24%;
}

.createUserPageContainer div {
    margin-top: 3%;
    text-decoration: underline;
    color: var(--bs-link-color);
    cursor: pointer;
}