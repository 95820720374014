.changePasswordPageContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 200px;
    margin-top: 5%;
}

.changePasswordPageContainer table {
    width: 50%;
}