.containerAgreementsPage {
    font-size: small;
    max-width: 95%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.deleteAgreementsContainer {
    margin: 1%;
    display: flex;
}

.modalDeleteContainer {
    max-width: 90%;
}

.modalDeleteContainer>div>label {
    cursor: pointer;
}

.modalDeleteContainer>div>input {
    margin-right: 1%;
    cursor: pointer;
}

.footerDeleteExpAndPaym {
    justify-content: center;
}

.yellowElement {
    background-color: rgba(254, 255, 168, 255);
}

.highlightElement {
    padding: 0.5em 0;
    border-bottom: 1px solid #eee;
    background-color: #fff !important;
    transition: all 0.4s cubic-bezier(.8, 0, .2, 1);
}

.tableAgreements>tbody>tr>td:nth-of-type(8)>label {
    width: 90px;
}