.containerResults {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.tableContainer {
    margin-top: 10%;
    width: 70%;
}

.table>thead {
    background-color: #8080804d;
}

.labelNotaria { 
    margin-left: 10px;
}
.backBtn {
    background: none;
    border: none;
    padding: 0px !important;
    margin: 0px !important;
    display: flex;
}
.backBtn > svg {
    width: 20px;
    height: 20px;
}
.openModalBtn {
    background: none;
    border: none;
    padding: 0px !important;
    color: var(--bs-link-color);
    text-decoration: underline;
    --bs-btn-hover-bg: none !important;
}
.openModalBtn:hover {
    cursor: pointer;
    background-color: none !important;
    color: var(--bs-link-hover-color);
}
.modal-dialog {
    max-width: 700px;
}
.modal-body {
    white-space: pre-wrap;
}
.searchLink {
    color: white;
    text-decoration: none;
    margin-left: 15px;
}
.searchLink:hover {
    color: rgb(120,120,120);
}